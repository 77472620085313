import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Alert from '../../Components/Alert/Alert';
import SubmitButton from '../../Components/Buttons-loaders-inputs/SubmitButton/SubmitButton'; 
import styles from './ResetPasswordForm.module.scss';
import variables from '../../Context/Variables';
import { Icon } from "@iconify/react";

const ResetPasswordForm = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { emailUrl } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Recuperar contraseña";
  }, []);

  useEffect(() => {
    if(emailUrl){
      setEmail(decodeURIComponent(emailUrl));
    }
  }, [emailUrl]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    fetch(variables.API_BASE_URL + '/api/usuarios/recover-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email })
    })
    .then(response => {
      if(response.ok) {
        return response.text();
      } else {
        return response.text().then(text => { throw new Error(text) });
      }
    })
    .then(message => {
      Alert({
        tipo: 'success',
        titulo: '¡Éxito!',
        descripcion: message,
        duracion: 4000,
        onClose: () => {
          navigate('/login');
        }
      });
    })
    .catch((error) => {
      Alert({
        tipo: 'error',
        titulo: 'Error',
        descripcion: error.message,
        duracion: 4000
      });
    })
    .finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <div className={styles.bodyReset}>
      <div className={styles.resetForm}>
        <h2>Recuperar contraseña <Icon icon="solar:key-bold" /></h2>
        <p>Ingresa tu correo electrónico y te enviaremos un enlace para restablecer tu contraseña.</p>
        <form id="resetPasswordForm" onSubmit={handleSubmit}>
          <input 
            type="email" 
            id="email" 
            placeholder="Correo electrónico" 
            required 
            value={email}
            onChange={(e) => setEmail(e.target.value.trim())}
          />
          <SubmitButton isDisabled={isLoading} color="#252525" isLoading={isLoading}>
            Enviar enlace
          </SubmitButton>
        </form>
        <div id="message"></div>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
