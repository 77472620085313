import React, { useState, useEffect, useCallback } from 'react';
import Teatro3deFebrero from '../Teatro3DeFebrero/Teatro3deFebrero';
import AsadoConCueroMap from '../AsadoConCuero/AsadoConCueroMap';
import styles from "./SeccionButacas.module.scss";
import MapaCarnavalesHasenkamp from '../CarnavalesHasenkamp/MapaCarnavalesHasenkamp';

const SeccionButacas = ({ evento, onUpdateSelectedSeats }) => {
  const [asientosSeleccionados, setAsientosSeleccionados] = useState([]);

  const handleAsientosSeleccionados = useCallback((selectedSeats) => {
    setAsientosSeleccionados(selectedSeats);
  }, []);

  useEffect(() => {
    if (onUpdateSelectedSeats) {
      onUpdateSelectedSeats(asientosSeleccionados);
    }
  }, [asientosSeleccionados, onUpdateSelectedSeats]);

  const getMapComponent = () => {
    const mapTypes = {
      Teatro3deFebrero: ["874377791117853669", "8078739981077", "7574007933657", "993353319872653177"],
      AsadoConCueroMap: ["973010338337324012", "294080502907478482", "977669016195915294"],
      Carnavales: ["95286417505483","879709173494496730", "652357833838600815", "184426453822749210", "728527369782477584", "80345186642440087", "300928546192875040", "969021803903477044", "416579169849015134"],
    };

    if (mapTypes.Teatro3deFebrero.includes(evento.id)) {
      return <Teatro3deFebrero evento={evento} onSeatsSelected={handleAsientosSeleccionados} />;
    } else if (mapTypes.AsadoConCueroMap.includes(evento.id)) {
      return <AsadoConCueroMap evento={evento} onSeatsSelected={handleAsientosSeleccionados} />;
    } else if (mapTypes.Carnavales.includes(evento.id)) {
      return <MapaCarnavalesHasenkamp evento={evento} onSeatsSelected={handleAsientosSeleccionados} />;
    }
    return null;
  };

  return (
    <div className={styles.bodyButacas}>
      <div className={styles.header}>
        <h4>Butacas <small>(no incluyen entrada)</small></h4>
        <p>Elige la sección y asientos que prefieras dentro del mapa</p>
      </div>
      {getMapComponent()}
    </div>
  );
};

export default SeccionButacas;